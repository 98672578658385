#firstpay-iframe {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.firstpay-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
}
.firstpay-label {
  font-weight: normal;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.firstpay-input {
  display: block;
  width: 75%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
select.firstpay-input:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}
.firstpay-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.firstpay-button:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.firstpay-button:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

ion-backdrop {
  /*opacity: 0.9;*/
  background: #868686;
}

#box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 100;
  display: flex;
  align-items: begin;
  justify-content: center;
  background: var(--ion-background-color, #fff);
  width: 50%;
  height: 90%;
  border-radius: 10px;
  overflow-y:auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#error {
  display: block;
  color: red;
  margin: 0px 0 0 0;
}
