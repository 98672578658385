@import "../../theme/variables.css";

/*.custom-message .modal-wrapper {*/
/*    */
/*    width:204px;*/
/*    background-color: white;*/
/*    position: fixed;*/
/*    bottom: 0px;*/
/*    box-shadow: 0px 3px 6px #00000026;*/
/*    border-radius: 40px 40px 0px 0px;*/
/*    font-family: var(--ion-font-family);*/
/*}*/

@keyframes bouncePrice {
    from,
    20%,
    53%,
    80%,
    to {
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        transform: translate3d(0, -30px, 0);
    }

    70% {
        transform: translate3d(0, -15px, 0);
    }

    90% {
        transform: translate3d(0, -4px, 0);
    }
}

.updated {
    animation: bouncePrice 1s ease infinite;
}

