.ion-button-update-payment {
    --background: #ffffff;
    --background-hover: #8f8e8e;
    --background-activated: #8F8E8EFF;
    --background-focused: #8F8E8EFF;

    --color: red;

    /*--border-radius: 0;*/
    --border-color: #000;
    /*--border-style: solid;*/
    --border-width: 1px;

    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

    /*--ripple-color: deeppink;*/

    --padding-top: 10px;
    --padding-bottom: 10px;
}