/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: lighter;
  src: url('../assets/fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/OpenSans-Bold.ttf');
}

#root {
  height: 100%;
  width: 100%;
}

ion-content {
  --ion-background-color: #f2f2f2;
  --keyboard-offset: 0px;
}

ion-footer {
  --ion-background-color: #f2f2f2;
  text-align: center;
}

ion-footer-bar {
  --ion-background-color: #f2f2f2;
  text-align: center;
}

IonFooter {
  --ion-color-primary: #f2f2f2;
  text-align: center;
}

ion-toolbar {
  --ion-color-primary: #f2f2f2;
  text-align: center;
  --min-height:65px!important;
}

ion-card {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 100px;
  height: auto;
}

ion-thumbnail {
  --size: 140px;
  --border-radius: 14px;
}

ion-toggle {
  --background: #8a8a8a;
  --background-checked: #5732DB;

  --handle-background: #efefef;
  --handle-background-checked: #efefef;
  padding-left: 0px;
  padding-right: 0px;
}

a {
  color: #d2222d;
}

ion-card-title {
  color: #d2222d;
}

ion-badge {
  font-size: 0.7rem;
}

ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.auto-height .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}

ion-card {
  background: #ffffff;
  color: #4c4c4c;
}

.searchBar {
  --background: #ffffff60;
  --placeholder-color: black;
  --color: var(--primary);
}

.searchBar .searchbar-input-container {
  height: 44px;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-font-family: 'Open Sans', sans-serif !important;
  /*--ion-color-primary: #3880ff;*/
  --ion-color-primary: #5732db;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #868686;
  --ion-color-primary-tint: #868686;
  /*--ion-color-primary-shade: #868686;*/
  /*--ion-color-primary-tint: #868686;*/

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #D2222D;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

ion-toast.custom-toast {
  --background: #fafafa;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #fcfcfc;
  z-index: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
}

ion-toast.custom-toast::part(message) {
  font-style: italic;
  font-size: 24px;
  color: black;
  align-items: center;
  justify-content: center;
  text-align: center;
}

ion-toast.custom-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  color: #5e5e5e;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

