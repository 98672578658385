.ion-button-payment-selected {
    --background: #ffffff;
    --background-hover: #ffffff;
    --background-activated: #ffffff;
    --background-focused: #ffffff;

    --color: red;

    /*--border-radius: 0;*/
    --border-color: #000;
    /*--border-style: solid;*/
    --border-width: 2px;

    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

    /*--ripple-color: deeppink;*/

    --padding-top: 10px;
    --padding-bottom: 10px;
}

.ion-button-payment {
    --background: #ffffff;
    /*--background-hover: #e3e2e2;*/
    /*--background-activated: #8F8E8EFF;*/
    /*--background-focused: #8F8E8EFF;*/

    --color: #8d8989;

    /*--border-radius: 0;*/
    --border-color: #000;
    /*--border-style: solid;*/
    --border-width: 1px;

    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

    /*--ripple-color: deeppink;*/

    --padding-top: 10px;
    --padding-bottom: 10px;
}