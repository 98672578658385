/*ion-tab-bar {*/
/*    border: 0;*/
/*    padding-top: 10px;*/
/*}*/

ion-tab-button {
    --background-focused: #8174af;
    --color-selected: #5732db;
    --color: #868686;
}

/*ion-col {*/
/*    --color: red;*/
/*    display: flex;*/
/*}*/

ion-col-custom {
    --color: red;
    display: flex;
}

.center-align-input {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-item: center;
    /*padding-right: 100px;*/
}

.right-align-input {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-item: end;
    /*padding-right: 100px;*/
}

.left-align-input {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-item: begin;
}