@import '../../theme/variables.css';

.custom-message .modal-wrapper {
  height: 204px;
  height:25%;
  background-color: white;
  position: fixed;
  bottom: 0px;
  box-shadow: 0px 3px 6px #00000026;
  border-radius: 40px 40px 0px 0px;
  font-family: var(--ion-font-family);
}
